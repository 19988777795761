<template>
  <div class="quick-filters-wrap">
    <mobileGender v-if="!isDesktop" :filter="filters[0]" />

    <!-- Modal Start -->
    <div class="micromodal" id="modal-quick-filters">
      <div tabindex="-1" data-micromodal-close>
        <div role="dialog" aria-modal="true">
          <header>
            <button aria-label="Close modal" data-micromodal-close></button>
          </header>
          <div id="modal-quick-filters-content">
            <!-- Quick Filters Start -->
            <div class="quick-filters">
              <div class="timeline timeline-steps opacity-0">
                <template v-for="(filter, index) in filters" :key="filter.attribute_code">
                  <div :class="stepClasses('timeline-step', index+1)" v-bind="stepData(filter.attribute_code)"></div>
                </template>
              </div>
              <div class="step-wrapper">
                <button v-if="isDesktop" class="clear-all" @click="clearAll()">Clear All</button>
                <template v-for="(filter) in filters" :key="filter.attribute_code">
                  <div v-if="canShowFacet(filter)" :data-sort-order="filter.mobile_sort" :data-step="filter.mobile_sort" :class="[filter.attribute_code, stepClasses('step', filter.mobile_sort)]" v-bind="stepData(filter.attribute_code)">
                    <component :is="filter.attribute_code" :icons="this.icons" :step="filter.mobile_sort" :filter="filter" :results="componentResults(filter.attribute_code)" @facet-change="call"></component>
                  </div>
                </template>

                <div class="step" :class="[`step-${this.filters.length + 1}`]" data-go>
                  <ButtonDesktop />
                </div>
                <div class="step" :class="[`step-${this.filters.length + 2}`]" data-loading>
                  <Loading />
                </div>
              </div>
            </div>
            <!-- Quick Filters End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal End -->
  </div>
</template>


<script>
  import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';

  import { default as gender } from "@/components/steps/Gender.vue";
  import { default as item_type } from "@/components/steps/ProductType.vue";
  import { default as brand } from "@/components/steps/Brands.vue";
  import { default as color } from "@/components/steps/Colors.vue";
  import { default as mobileGender } from "@/components/steps/MobileGender.vue";

  import ButtonDesktop from "@/components/ButtonDesktop.vue";
  import Loading from "@/components/steps/Loading.vue";
  import { selectedFilters, store } from '@/store'
  import MicroModal from 'micromodal';

  // Close dropdowns if you click outside it
  document.addEventListener('click', function (e) {
    if(e.target.closest('.custom-select.active') != null) {
      return
    }

    let customSelectActive = document.querySelector('.custom-select.active');
    if (customSelectActive) {
      customSelectActive.classList.remove('active');
    }
  });

  // Intersection Observer
  window.onload=function(){
    if (window.IntersectionObserver) {
      if(window.innerWidth > 1024) {
        return;
      }

      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
            let currentStep = entry.target.getAttribute('data-step');
            console.log('currentStep: ' + currentStep);
            let currentStepMaxHeight = entry.target.clientHeight + 40 + 'px';
            if(currentStep == 2 && document.querySelector('.timeline-steps.opacity-0')) {
              document.querySelector('.timeline-steps.opacity-0').classList.remove('opacity-0')
            } else if(currentStep == 1 && document.querySelector('.timeline-steps:not(.opacity-0)')) {
              document.querySelector('.timeline-steps:not(.opacity-0)').classList.add('opacity-0')
            }

            let timelineActive = document.querySelector('.timeline-step.active');
            if (timelineActive) {
              timelineActive.classList.remove('active');
            }

            let timeLineStep = document.querySelector('.timeline-step.step-' + currentStep);
            if (timeLineStep) {
              timeLineStep.classList.add('active')
            }

            document.querySelector('.step-wrapper').style.maxHeight = currentStepMaxHeight
          } else {
            entry.target.classList.remove('active');
          }
        });
      }, {rootMargin: "0px 0px -300px 0px"});

      document.querySelectorAll('.step').forEach(entry => observer.observe(entry));
    }
  };

  export default {
    name: "QuickFilters",
    setup(props) {
      store.setRedirects(props.redirects);

      window.onpageshow = event => {
        if (event.persisted) {
          this.clearAll();
        }
      }
    },
    components: {
      gender,
      item_type,
      brand,
      color,
      ButtonDesktop,
      Loading,
      mobileGender
    },
    props: [
      "filters",
      "redirects",
      "icons"
    ],
    data() {
      return {
        store,
        results: [],
        isDesktop: window.innerWidth > 1023
      }
    },
    created() {
      this.call();
    },
    methods: {
      call() {
        let gender = '401'; // Women's

        if (store.selectedGender === 'Mens') {
          gender = '434'; // Men's
        }

        this.browse('group_id', gender);
      },
      browse(filterName = 'group_id', filterValue = window.search.filter) {
        let self = this,
            browse = this.getSearchClient().browse;

        browse.getBrowseResults(
          filterName,
          filterValue,
          {
            filters: selectedFilters.value
          }
        ).then(function (json) {
          self.results = json.response;
        });
      },
      getSearchClient() {
        let searchClientConfig = {
          apiKey: window.search.apiKey,
          fetch: fetch
        };

        if (window.cnstrcUserId) {
          searchClientConfig.userId = window.cnstrcUserId;
        } else {
          let mageCacheStorage = JSON.parse(localStorage.getItem('mage-cache-storage'));

          if (mageCacheStorage?.customer?.id) {
            searchClientConfig.userId = mageCacheStorage.customer.id;
          }
        }

        return new ConstructorIOClient(searchClientConfig);
      },
      stepClasses(baseClass, index) {
        let classes = [
          baseClass,
        ];

        classes.push(`step-${(index)} order-${(index)}`);

        if (index === 0) {
          classes.push('active');
        }

        return classes;
      },
      stepData(attribute_code) {
        return { [`data-select-${attribute_code}`]: '' }
      },
      componentResults(attributeCode) {
        return this.results.facets?.find(({name}) => name === attributeCode) || [];
      },
      canShowFacet(filter) {
        let showFacet = true;
        if (!this.isDesktop && filter.display_on_mobile === '0') {
          showFacet = false;
        }
        return showFacet;
      },
      clearAll() {
        store.setGender('Womens');
        store.setProductType([]);
        store.setColor([]);
        store.setBrand([]);
      }
    },
    mounted() {
      MicroModal.init();
    }
  }
</script>

<style scoped>
* {
  /* --font-size-small - 14px */
  font-size: var(--font-size-small);
}
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes move-up {
  from { transform: translateY(0%); }
  from { transform: translateY(-100%); }
}
@keyframes fade-in-up {
  from { opacity: 0; transform: translateY(100px) }
  to { opacity: 1; transform: translateY(0px) }
}
.quick-filters {
  z-index: 200;
  position: relative;
}
.quick-filters :deep(ol) {
  list-style: none;
  margin: 0;
  padding: 0;
}
.opacity-0 {
  opacity: 0;
}
.timeline {
  display: flex;
  gap: 10px;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 30px;
  margin: 0;
  background: var(--color-white);
  z-index: 1;
}
.timeline.loading {
  opacity: 0;
}
.timeline .timeline-step {
  height: 5px;
  width: calc(100% / 4);
  background-color: var(--brand-primary);
  transition: all 0.3s ease;
}
.timeline .timeline-step.active {
  background-color: var(--color-orage);
}
:deep(.step-actions) {
  margin: 30px auto;
}
:deep(.step-actions button) {
  background: var(--color-black);
  border: 0;
  color: var(--color-white);
  height: 42px;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  font-size: var(--font-size-large);
  max-width: 400px;
  transition: all 0.3s ease;
}
:deep(.step-actions button.skip:hover) {
  background: var(--brand-primary);
  color: var(--color-white);
}
:deep(.step-actions button.next) {
  background: var(--color-black);
  border-radius: 3px;
  color: var(--color-white);
}
:deep(.step-actions button.next:hover) {
  background: var(--color-black);
}

.step-wrapper {
  position: relative;
  scroll-behavior: smooth;
}

.clear-all {
  all: unset;

  color: var(--color-dark-gray);
  cursor: pointer;
  line-height: 31.5px;
  font-size: var(--font-size-medium);
  position: absolute;
  right: 0;
  top: 0;
}

/*
// Desktop
*/
@media only screen and (min-width: 1024px) {
  #modal-quick-filters header {
      display: none;
  }
}

/*
// Mobile
*/
@media only screen and (max-width: 1023px) {
  :deep(.ready),
  :deep(.almost) {
    font-size: var(--font-size-medium);
    margin: 1em 0 !important; /* Override overly specific Magento selector */
  }

  #modal-quick-filters-content {
    padding: 0 15px;
    overflow: scroll;
    width: calc(100vw - 30px);
    height: 100vh;
    margin: 0 auto;
  }
  .timeline {
    padding: 10px 0;
  }
  #modal-quick-filters header {
    text-align: right;
    padding: 10px 0 0;
    width: 100vw;
  }
  button[data-micromodal-close] {
    background: transparent;
    padding: 0;
    border: 0;
    box-shadow: none;
    width: 40px;
    height: 40px;
    cursor:pointer;
  }
  button[data-micromodal-close]:after {
    content: "\00d7";
    font-size: var(--heading-font-size-h1);
    color: var(--color-black);
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .quick-filters {
    position: relative;
  }
  .quick-filters .step {
    text-align: center;
    transition: all 0.3s ease;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    padding-bottom: 30px;
  }
  .quick-filters .step-wrapper {
    max-height: 280px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .quick-filters .step-wrapper.ready {
    overflow-y: scroll;
    min-height: 80vh;
  }
  .quick-filters .step-wrapper:not(.ready) {
    overflow: hidden;
  }
  .quick-filters .step-wrapper::-webkit-scrollbar {
    display: none;
  }
  .quick-filters .step-wrapper.loading {
    overflow: hidden;
    max-height: 342px !important;
    height: 100vh;
  }
  .quick-filters .step-wrapper.loading .step:not(.step-6) {
    display: none !important
  }
  div.step.loading.active {
    opacity: 0 !important;
  }
  .quick-filters .step:not(.step-1):not(.step-6) {
    margin-top: 450px;
  }
  .quick-filters .step.step-3, .quick-filters .step.step-4 {
    margin-top: 0 !important;
  }
  .quick-filters .step.step-4 {
    margin-bottom: 30px;
  }
  .quick-filters :deep(.custom-select .select-selected) {
    display: none;
  }
  .quick-filters :deep(.step-mobile-label) {
    max-width: 300px;
    margin: 30px auto;
  }
  .quick-filters :deep(.step-mobile-label h2) {
    font-weight: var(--font-weight-normal);
    font-size: 1.5em;
    display: inline-block;
    width: 100%;
    margin: 0;
    text-transform: none;
  }
  .quick-filters .step-6:not(.active) {
      display: none;
  }
  .quick-filters .step-6.active {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quick-filters .step-5 {
    display: none;
  }
}
/*
// Desktop
*/
@media only screen and (min-width: 1024px) {
  .timeline.timeline-steps {
    display: none;
  }
  .quick-filters {
    padding: 0 15px;
    margin: 30px auto;
  }
  .quick-filters :deep(.step-mobile-label) {
    display: none;
  }
  .quick-filters .gender[data-step] {
    margin-bottom: 24px;
  }
  .quick-filters .item_type[data-step],
  .quick-filters .brand[data-step],
  .quick-filters .color[data-step] {
    display: inline-block;
    width: calc((100% / 3) - 43px);
    vertical-align: top;
    margin-right: 15px;
  }
  .quick-filters .step-5 {
    display: inline-block;
    position: relative;
  }
  .quick-filters :deep(.custom-select) {
    position: relative;
  }
  .quick-filters :deep(.custom-select .select-selected) {
    height: 44px;
    border: 1px solid var(--color-gray);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    padding: 0 45px 0 20px;
    margin: 0;
    font-family: var(--font-family-base);
    font-size: var(--font-size-medium);
    line-height: 1.5;
    letter-spacing: 0.5px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTkuMDAxIDguMzQ3IDYuODQ4LTcuMTU5YS42NjcuNjY3IDAgMCAxIC45NzIgMCAuNzQyLjc0MiAwIDAgMSAwIDEuMDE3TDkuNDg3IDkuODcyYS42NjcuNjY3IDAgMCAxLS45NzIgMEwxLjE4MiAyLjIwNWEuNzQyLjc0MiAwIDAgMSAwLTEuMDE3LjY2Ny42NjcgMCAwIDEgLjk3MiAwbDYuODQ3IDcuMTU5WiIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: right 22px bottom 50%;
    cursor: pointer;
    transition: outline 0.2s ease, border 0.2s ease;
  }
  .quick-filters :deep(.custom-select:hover .select-selected),
  .quick-filters :deep(.custom-select.active .select-selected) {
    border: 1px solid var(--color-black);
    outline: 1px solid var(--color-black);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.21px;
  }
  .quick-filters :deep(.custom-select.active .select-selected) {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguOTk5IDIuNjUzIDIuMTUgOS44MTJhLjY2Ny42NjcgMCAwIDEtLjk3MiAwIC43NDIuNzQyIDAgMCAxIDAtMS4wMTdsNy4zMzQtNy42NjdhLjY2Ny42NjcgMCAwIDEgLjk3MiAwbDcuMzMzIDcuNjY3YS43NDIuNzQyIDAgMCAxIDAgMS4wMTcuNjY3LjY2NyAwIDAgMS0uOTcyIDBMOC45OTkgMi42NTNaIiBmaWxsPSIjMDAwIi8+PC9zdmc+');
  }
  .quick-filters :deep(.custom-select .select-items) {
    max-height: 287px;
    background: var(--color-white);
    border: 1px solid var(--color-light-gray);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    overflow-y: scroll;
  }
  .quick-filters :deep(.custom-select.active .select-items) {
    animation: fade-in .3s forwards;
    position: absolute;
  }
  .quick-filters :deep(.custom-select:not(.active) .select-items) {
    display: none;
  }
  .quick-filters :deep(.custom-select .select-items span.text) {
    font-size: var(--font-size-medium);
    margin-left: 42px;
    display: block;
    cursor: pointer;
  }
  .quick-filters :deep(.custom-select .select-items ol:not(.color) li) {
    margin-bottom: 13px;
    position: relative;
    display: flex;
    align-items: center;
    height: 25px;
  }
  .quick-filters :deep(.custom-select .select-items ol input) {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .quick-filters :deep(.custom-select .select-items ol:not(.color) .checkmark) {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: var(--color-white) !important;
    border: 2px solid var(--color-black);
    border-radius: 3px;
  }
  .quick-filters :deep(.custom-select .select-items ol:not(.color) input:checked ~ .checkmark) {
    background-color: var(--color-white);
  }
  .quick-filters :deep(.custom-select .select-items ol:not(.color) input:checked ~ .checkmark:after) {
    display: block;
  }
  .quick-filters :deep(.custom-select .select-items ol:not(.color) .checkmark:after) {
    content: "";
    display: none;
    position: absolute;
    left: 6px;
    top: 1px;
    width: 9px;
    height: 14px;
    border: solid var(--color-black);
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .quick-filters :deep(.custom-select .count) {
    margin-left: 5px;
  }
  .quick-filters :deep(.filter_active .count:before) {
    content: "("
  }
  .quick-filters :deep(.filter_active .count:after) {
    content: ")"
  }
}

/* Micromodal */
@media only screen and (max-width: 1024px) {
  .micromodal {
    display: none;
  }

  .micromodal.is-open {
    display: block;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background: var(--color-white);
  }

  .micromodal__overlay {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.65);
  }

  .micromodal__container {
    overflow-y: auto;
    max-width: 500px;
    max-height: 100vh;
    padding: 30px;
    background-color: var(--color-white);
    border-radius: 4px;
  }

  .micromodal[aria-hidden="false"] {
    animation: microModalFadeIn .2s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal .micromodal__container,
  .micromodal .micromodal__overlay {
    will-change: transform;
  }
}

@keyframes microModalFadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes microModalSlideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}
</style>
