<template>
  <div class="step-6__wrap" data-wrap>
    <div class="step-6__mobile-label step-mobile-label">
        <div class="loading-icon">
            <span class="tshirt tshirt-0"><TShirt /></span>
            <span class="tshirt tshirt-1"><TShirt /></span>
            <span class="tshirt tshirt-2"><TShirt /></span>
        </div>
        <h2>Loading Your Results...</h2>
    </div>
  </div>
</template>

<script>
import TShirt from "@/components/icons/TShirt.vue";

export default {
    name: "Loading",
    components: {
        TShirt
    },
}
</script>

<style scoped>
@keyframes loading {
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100%  { opacity: 0; }
}
@keyframes color-0 {
    0% { stroke: #334C4C; }
    100%  { stroke:  #8F4899; }
}
@keyframes color-1 {
    0% { stroke: #E73882; }
    100%  { stroke:  #979797; }
}
@keyframes color-2 {
    0% { stroke: #8FC3EA; }
    100%  { stroke:  #56C4C2; }
}
.tshirt {
    animation: loading 2s infinite;
    margin: 0 5px;
}
.tshirt-1 { animation-delay: .1s }
.tshirt-2 { animation-delay: .3s }
.tshirt :deep(svg path) {
    animation-duration: 6s;
    animation-iteration-count: infinite;
}
.tshirt-0 :deep(svg path) {
    animation-name: color-0;
}
.tshirt-1 :deep(svg path) {
    animation-name: color-1;
}
.tshirt-2 :deep(svg path) {
    animation-name: color-2;
}
h2 {
    max-width: 200px;
    margin: 20px auto 0;
}
</style>
