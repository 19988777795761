<template>
    <div :class="[`step__wrap`, `mobile-${filter.attribute_code}`]">
        <div class="step-mobile-label">
          <h2>{{ getLabel(filter) }}</h2>
        </div>

        <div class="step__options">
            <div class="step__option">
                <label class="label" for="womens-mobile">
                    <input id="womens-mobile" type="radio" :name="filter.attribute_code" value="Womens" @click="openModal($event)" />
                    <span class="checkmark"></span>
                    <span class="label-text">Women's <span class="mobile-text">Scrubs</span></span>
                </label>
            </div>
            <div class="step__option">
                <label class="label" for="mens-mobile">
                    <input id="mens-mobile" type="radio" :name="filter.attribute_code" value="Mens" @click="openModal($event)" />
                    <span class="checkmark"></span>
                    <span class="label-text">Men's <span class="mobile-text">Scrubs</span></span>
                </label>
            </div>
        </div>
    </div>
</template>


<script>
import { store } from '@/store'
import MicroModal from 'micromodal'

export default {
    name: "Gender",
    emits: ['facetChange'],
    props: {
        filter: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            store,
            isDesktop: window.innerWidth > 1023,
            active: null,
        };
    },
    methods: {
        getLabel(filter) {
            let label = filter.label;
            if (!this.isDesktop && filter.mobile_label) {
                label = filter.mobile_label;
            }
            return label;
        },
        onMicromodalShow() {
            let step2Offset = document.querySelector(`.step-2__wrap`).offsetTop - 40,
                timelineStep = document.querySelector(".timeline-steps.opacity-0");

            document.querySelector('.step-wrapper').scrollTo({top: step2Offset});

            if (timelineStep) {
                timelineStep.classList.remove('opacity-0')
            }

            document.querySelector('.step-wrapper').style.maxHeight = document.querySelector(`.step-2__wrap`).clientHeight + 40 + 'px';
            document.body.classList.add('quick-filters-open');
        },
        openModal(e) {
            MicroModal.show('modal-quick-filters', {
                onShow: this.onMicromodalShow,
                onClose: this.closeModal
            });
            document.querySelector(`.quick-filters input[value="${e.target.value}"]`).click();
        },
        closeModal() {
            document.body.classList.remove('quick-filters-open')
        }
    }
}
</script>

<style scoped>
/* Gender */
.mobile-gender .step__options {
    color: var(--color-black);
    display: flex;
    justify-content: center;
    padding: 0 5px;
    gap: 5%;
}
.mobile-gender {
    margin-top: -13px;
}
.mobile-gender .step-mobile-label {
    text-align: center;
}
.mobile-gender .step-mobile-label h2 {
    font-weight: var(--font-weight-normal);
    font-size: 1.5em;
    display: inline-block;
    width: 100%;
    text-transform: none;
    max-width: 300px;
    margin: 0 auto 30px;
}
.mobile-gender .step__options .step__option {
    flex: 1;
    max-width: 200px;
}
.mobile-gender .step__options .step__option .label {
    width: 100%;
    display: block;
    height: 0;
    padding-bottom: 60%;
    position: relative;
    cursor: pointer;
    border: 1px solid var(--color-black);
    border-radius: 9999px;
}
.mobile-gender .step__options .step__option .label-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
}
.mobile-gender input[name="gender"] {
    display: none;
}
.mobile-gender .label-text.active {
    background: var(--brand-primary);
    color: var(--color-white);
}
/* Desktop */
@media only screen and (min-width: 1024px) {
    .mobile-gender {
        display: none !important;
    }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .mobile-gender .step__options {
        gap: 30px;
    }
}
</style>
