<template>
    <div class="see-results">
        <button @click="submit" ref="button" id="mobile-quick-filter-button" class="next ready">
        <div class="message submitMessage">
            <span class="button-text">
                See Your Results!
            </span>
        </div>

        <div class="message loadingMessage">
            <Loading />
        </div>

        <div class="message successMessage">
            <Check />
        </div>
        </button>
        <canvas ref="canvas" id="mobile-confetti-canvas"></canvas>

        <button class="retake" @click="clearAll()">Retake Quiz</button>
    </div>
</template>

<script>
import Loading from "@/components/icons/Loading.vue";
import Check from "@/components/icons/Check.vue";

import { redirect } from "@/composables/redirect";
import { store } from '@/store'

export default {
    name: "Button Mobile",
    setup() {
        return { redirect }
    },
    components: {
        Loading,
        Check
    },
    methods: {
        submit(){
            // amount to add on each button press
            const confettiCount = 20
            const sequinCount = 10

            // "physics" variables
            const gravityConfetti = 0.3
            const gravitySequins = 0.55
            const dragConfetti = 0.075
            const dragSequins = 0.02
            const terminalVelocity = 3

            // init other global elements
            const button = this.$refs.button
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext('2d')
            canvas.width = 300;
            canvas.height = 350;

            // add Confetto/Sequin objects to arrays to draw them
            let confetti = []
            let sequins = []

            // colors, back side is darker for confetti flipping
            const colors = [
                { front : '#112332', back: '#135287' },
                { front : '#E73882', back: '#FF87BA' },
                { front : '#E98734', back: '#FFA960' },
                { front : '#112332', back: '#135287' },
                { front : '#16960B', back: '#7ADF71' }
            ]

            const randomRange = (min, max) => Math.random() * (max - min) + min

            // helper function to get initial velocities for confetti
            // this weighted spread helps the confetti look more realistic
            const initConfettoVelocity = (xRange, yRange) => {
                const x = randomRange(xRange[0], xRange[1])
                const range = yRange[1] - yRange[0] + 1
                let y = yRange[1] - Math.abs(randomRange(0, range) + randomRange(0, range) - range)
                if (y >= yRange[1] - 1) {
                    // Occasional confetto goes higher than the max
                    y += (Math.random() < .25) ? randomRange(1, 3) : 0
                }
                return {x: x, y: -y}
            }

            // Confetto Class
            function Confetto() {
                this.randomModifier = randomRange(0, 99)
                this.color = colors[Math.floor(randomRange(0, colors.length))]
                this.dimensions = {
                    x: randomRange(5, 9),
                    y: randomRange(8, 15),
                }
                this.position = {
                    x: randomRange(canvas.width/2 - button.offsetWidth/4, canvas.width/2 + button.offsetWidth/4),
                    y: randomRange(canvas.height/2 + button.offsetHeight/2 + 8, canvas.height/2 + (1.5 * button.offsetHeight) - 8),
                }
                this.rotation = randomRange(0, 2 * Math.PI)
                this.scale = {
                    x: 1,
                    y: 1,
                }
                this.velocity = initConfettoVelocity([-9, 9], [6, 11])
            }
            Confetto.prototype.update = function() {
                // apply forces to velocity
                this.velocity.x -= this.velocity.x * dragConfetti
                this.velocity.y = Math.min(this.velocity.y + gravityConfetti, terminalVelocity)
                this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()

                // set position
                this.position.x += this.velocity.x
                this.position.y += this.velocity.y

                // spin confetto by scaling y and set the color, .09 just slows cosine frequency
                this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09)
            }

            // Sequin Class
            function Sequin() {
                this.color = colors[Math.floor(randomRange(0, colors.length))].back
                this.radius = randomRange(1, 2)
                this.position = {
                    x: randomRange(canvas.width/2 - button.offsetWidth/3, canvas.width/2 + button.offsetWidth/3),
                    y: randomRange(canvas.height/2 + button.offsetHeight/2 + 8, canvas.height/2 + (1.5 * button.offsetHeight) - 8),
                }
                this.velocity = {
                    x: randomRange(-6, 6),
                    y: randomRange(-8, -12)
                }
            }

            Sequin.prototype.update = function() {
                // apply forces to velocity
                this.velocity.x -= this.velocity.x * dragSequins
                this.velocity.y = this.velocity.y + gravitySequins

                // set position
                this.position.x += this.velocity.x
                this.position.y += this.velocity.y
            }

            // add elements to arrays to be drawn
            const initBurst = () => {
                for (let i = 0; i < confettiCount; i++) {
                    confetti.push(new Confetto())
                }
                for (let i = 0; i < sequinCount; i++) {
                    sequins.push(new Sequin())
                }
            }

            // draws the elements on the canvas
            const render = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height)

                confetti.forEach((confetto) => {
                    let width = (confetto.dimensions.x * confetto.scale.x)
                    let height = (confetto.dimensions.y * confetto.scale.y)

                    // move canvas to position and rotate
                    ctx.translate(confetto.position.x, confetto.position.y)
                    ctx.rotate(confetto.rotation)

                    // update confetto "physics" values
                    confetto.update()

                    // get front or back fill color
                    ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back

                    // draw confetto
                    ctx.fillRect(-width / 2, -height / 2, width, height)

                    // reset transform matrix
                    ctx.setTransform(1, 0, 0, 1, 0, 0)

                    // clear rectangle where button cuts off
                    if (confetto.velocity.y < 0) {
                    ctx.clearRect(canvas.width/2 - button.offsetWidth/2, canvas.height/2 + button.offsetHeight/2, button.offsetWidth, button.offsetHeight)
                    }
                })

                sequins.forEach((sequin) => {
                    // move canvas to position
                    ctx.translate(sequin.position.x, sequin.position.y)

                    // update sequin "physics" values
                    sequin.update()

                    // set the color
                    ctx.fillStyle = sequin.color

                    // draw sequin
                    ctx.beginPath()
                    ctx.arc(0, 0, sequin.radius, 0, 2 * Math.PI)
                    ctx.fill()

                    // reset transform matrix
                    ctx.setTransform(1, 0, 0, 1, 0, 0)

                    // clear rectangle where button cuts off
                    if (sequin.velocity.y < 0) {
                    ctx.clearRect(canvas.width/2 - button.offsetWidth/2, canvas.height/2 + button.offsetHeight/2, button.offsetWidth, button.offsetHeight)
                    }
                })

                // remove confetti and sequins that fall off the screen
                // must be done in seperate loops to avoid noticeable flickering
                confetti.forEach((confetto, index) => {
                    if (confetto.position.y >= canvas.height) confetti.splice(index, 1)
                })
                sequins.forEach((sequin, index) => {
                    if (sequin.position.y >= canvas.height) sequins.splice(index, 1)
                })
                window.requestAnimationFrame(render)
            }

            initBurst();
            setTimeout(() => {
                document.querySelector('.step.step-4.active').classList.add('loading')
                document.querySelector('.step.step-6').classList.add('active')
                document.querySelector('.timeline.timeline-steps').classList.add('loading')
                document.querySelector('.step-wrapper').classList.add('loading')
                setTimeout(() => {
                    redirect();
                }, 1020)
            }, 1800)

            // kick off the render loop
            render();
        },
        clearAll() {
            store.setGender('')
            store.setProductType([])
            store.setColor([])
            store.setBrand([])

            document.querySelector('.step-wrapper').scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style scoped>
@keyframes loading {
  0%   { cy: 10; }
  25%  { cy: 3; }
  50%  { cy: 10; }
}

.see-results {
    position: relative;
}

canvas {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    top: -150px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

#mobile-quick-filter-button {
    background: var(--brand-primary);
    border: none;
    font-family: var(--font-family-base);
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-white);
    -webkit-tap-highlight-color: transparent;
    position: relative;
    width: 100%;
    margin: 0 auto 10px !important;
    max-width: 400px;
    padding: 0;
    font-weight: var(--font-weight-normal);
}

#mobile-quick-filter-button::before {
    border-radius: 3px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 40%) inset;
    color: var(--color-white);
    content: "";
    display: block;
    height: 100%;
    margin: 0 auto;
    position: relative;
    transition: width 0.2s cubic-bezier(0.39, 1.86, 0.64, 1) 0.3s;
    width: 100%;
}

#mobile-quick-filter-button :deep(svg) {
    margin-left: 10px;
}

#mobile-quick-filter-button.ready .submitMessage :deep(svg) {
    opacity: 1;
    top: 1px;
    transition: top 0.4s ease 600ms, opacity 0.3s linear 600ms;
}

#mobile-quick-filter-button.ready .submitMessage .button-text span {
    top: 0;
    opacity: 1;
}

.button-text span {
  opacity: 0;
  position: relative;
}

.message {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.message :deep(svg) {
  display: inline-block;
  fill: none;
  margin-left: 5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.submitMessage .button-text span {
    top: 8px;
}

.submitMessage :deep(svg) {
    color: var(--color-white);
    margin-right: -1px;
    opacity: 0;
    position: relative;
    transition: opacity .3s linear;
    width: 14px;
}

#mobile-quick-filter-button.loading .loadingMessage {
    opacity: 1;
}

#mobile-quick-filter-button.loading :deep(.loadingCircle) {
    animation: loading 1s infinite;
    cy: 10;
}

#mobile-quick-filter-button.loading :deep(.loadingCircle:nth-child(2)) { animation-delay: .1s }
#mobile-quick-filter-button.loading :deep(.loadingCircle:nth-child(3)) { animation-delay: .2s }

.loadingMessage {
  opacity: 0;
  transition: opacity .3s linear .3s, top .4s cubic-bezier(.22,0,.41,-0.57);
}
.loadingMessage :deep(svg) {
    fill: var(--color-white);
    margin: 0;
    width: 22px;
}

#mobile-quick-filter-button.complete .submitMessage :deep(svg) {
    top: -30px;
    transition: none;
  }

#mobile-quick-filter-button.complete .submitMessage .button-text span {
    top: -8px;
    transition: none;
  }

#mobile-quick-filter-button.complete .loadingMessage {
    top: 80px;
  }
#mobile-quick-filter-button.complete .successMessage {
    opacity: 1;
}

#mobile-quick-filter-button.complete .successMessage .button-text span {
    left: 0;
    opacity: 1;
}

button.complete .successMessage :deep(svg) {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset .3s ease-in-out 0.6s;
  }

.successMessage {
    opacity: 0;
}

.successMessage .button-text span {
    left: 5px;
    transition: all .2s ease 150ms;
}

.successMessage :deep(svg) {
    color: var(--color-white);
    stroke-dasharray: 20;
    stroke-dashoffset: 20;
    transition: stroke-dashoffset .3s ease-in-out;
    width: 14px;
}

.step-actions button.retake {
    background: unset;
    border: unset;
    color: var(--color-black);
    font-size: var(--font-size-large);
    text-decoration: underline;
    text-underline-offset: 4px;
}
</style>
