<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17">
    <circle class="loadingCircle" cx="2.2" cy="10" r="1.6"/>
    <circle class="loadingCircle" cx="9.5" cy="10" r="1.6"/>
    <circle class="loadingCircle" cx="16.8" cy="10" r="1.6"/>
    </svg>
</template>

<script>
export default {
  name: "Loading"
}
</script>
