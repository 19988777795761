<template>
    <svg width="80" height="66" viewBox="0 0 80 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.1987 64.8842L24.0073 31.2341C23.8161 29.6994 22.03 28.9531 20.8038 29.8955L12.7713 36.0692L1.43312 22.1595L25.0113 4.48365L38.2265 13.3933C39.0551 13.9519 40.1689 13.8132 40.8352 13.0684L51.4546 1.19708L78.6528 12.5664L71.0659 28.8004L61.7651 24.7971C60.3454 24.186 58.7987 25.3475 58.9897 26.8813L63.1813 60.5331L28.1987 64.8842Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>
    </svg>
</template>

<script>
export default {
  name: "TShirt"
}
</script>
