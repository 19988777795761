<template>
  <div :class="[`step-${step}__wrap step__wrap`, filter.attribute_code, store.selectedBrand.length > 0 ? 'filter_active': '']" data-wrap>
    <div :class="[`step-${step}__mobile-label step-mobile-label`]">
        <h2>{{ getLabel(filter) }}</h2>
    </div>
    <div :class="[`step-${step}__select custom-select`]" :data-step="step">
      <div class="select-selected" @click="toggleFilters">{{ getLabel(filter) }}
        <span class="count" v-if="store.selectedBrand.length > 0">
      {{ store.selectedBrand.length > 0 ? store.selectedBrand.length : '' }}
      </span></div>
      <div :class="['select-items select-hide']">
        <ol class="items">
            <li v-for="option in getOptionsToShow(results.data, results.options)" :key="option.value">
            <label class="label" :for="results.name + ':' + option.value" :data-label="option.value">
                <input type="checkbox"
                  :id="results.name + ':' + option.value"
                  v-model="store.selectedBrand"
                  :value="option.value"
                  :data-filter="results.name + ':' + option.value"
                  @change="activeFilters"
                />
                <span class="checkmark"></span>
                <span class="text">{{ option.display_name }}</span>
            </label>
            </li>
        </ol>
      </div>
    </div>
    <div :class="[`step-${step}__actions step-actions`]">
      <p :class="['almost', step == '4' ? 'final' : '']" v-if="step == '4' || step == '3'">Almost There...</p>
      <p class="ready" v-if="step == '4'">Let’s Do This!</p>

      <button class="button skip" v-if="step == '4'" @click="showNextButton">Skip</button>
      <button class="button skip" v-if="step != '4'" @click="triggerNextStep">Skip</button>
      <button class="button next" v-if="step != '4'" @click="triggerNextStep">Next</button>

      <ButtonMobile v-if="step == '4'" />
    </div>
  </div>
</template>

<script>
  import ButtonMobile from  "@/components/ButtonMobile.vue";
  import { store } from '@/store'

  export default {
    name: "Brands",
    components: {
      ButtonMobile
    },
    props: {
      filter: {
        type: Object,
        required: true
      },
      results: {
        type: Object,
        required: true
      },
      step: {
          default: '',
          type: String,
          required: true
      }
    },
    data() {
        return {
          store,
          isDesktop: window.innerWidth > 1023
        }
    },
    emits: ['facetChange'],
    methods: {
      triggerNextStep() {
        let nextStep = parseFloat(this.step) + 1;
        document.querySelector(`.step.step-${this.step}.active`).classList.remove('active')
        document.querySelector(`.timeline-step.step-${this.step}.active`).classList.remove('active')
        document.querySelector(`.timeline-step.step-${nextStep}`).classList.add('active')
        document.querySelector(`.step.step-${nextStep}`).classList.add('active')
          let nextStepOffset = document.querySelector(`.step-${nextStep}__wrap`).offsetTop - 40;
          if(!this.isDesktop) {
            document.querySelector('.step-wrapper').scrollTo({
              top: nextStepOffset,
              behavior: 'smooth'
            });
            document.querySelector('.step-wrapper').style.maxHeight = document.querySelector(`.step-${nextStep}__wrap`).clientHeight + 40 + 'px'
          }
      },
      showNextButton(e) {
        e.target.closest('[data-wrap]').classList.add('filter_see_results')
      },
      toggleFilters(e) {
        let thisStep = e.target.closest('.custom-select').getAttribute('data-step'),
            customSelect = document.querySelector('.custom-select.active:not([data-step="' + thisStep + '"])');

        if (customSelect) {
          document.querySelector('.custom-select.active').classList.remove('active');
        }

        e.target.closest('.custom-select').classList.contains('active') ?
            e.target.closest('.custom-select').classList.remove('active')
            :
            e.target.closest('.custom-select').classList.add('active')
      },
      activeFilters() {
        let brands = store.selectedBrand

        // Add updated vmodel to local storage
        store.setBrand(brands);

        this.$emit('facetChange');
      },

      getOptionsToShow(data, options) {
          // M2PL-3490-removed showMore options
          if (data?.max_number_filter_options){
            return options.slice(0, data.max_number_filter_options)
          }
          return options;
      },

      getLabel(filter) {
        let label = filter.label;
        if (!this.isDesktop && filter.mobile_label) {
            label = filter.mobile_label;
        } else if (filter.desktop_label) {
            label = filter.desktop_label;
        }
        return label;
      }
    }
  }
</script>


<style scoped>
  .brand .step-actions button {
    height: 42px;
    border-radius: 3px;
    width: 100%;
    cursor: pointer;
    max-width: 400px;
  }

  /*
  //  Mobile
  */
  @media only screen and (max-width: 1023px) {
    .brand.step__wrap ol.items li:nth-child(n+10) {
      display: none;
    }
    .brand .step-actions button.next:hover {
      background: var(--color-black);
    }
    .brand .custom-select ol.items {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
    }
    .brand .custom-select li label {
      margin-bottom: 13px;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .brand .custom-select li label .text {
      background: #E0E4E4;
      height: 42px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: all 0.3s ease;
      cursor: pointer;
      font-size: var(--font-size-medium);
    }
    .brand .custom-select li label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .brand .custom-select li label input:checked ~ .text {
      background: var(--brand-primary);
      color: var(--color-white);
    }
    [data-wrap].filter_active .button.next,
    [data-wrap]:not(.filter_active) .button.skip {
      animation: fade-in .3s forwards;
      letter-spacing: 0.608326px;
    }
    [data-wrap]:not(.filter_active) .button.next,
    [data-wrap].filter_active .button.skip {
      display: none;
    }
    [data-wrap].filter_see_results .ready,
    [data-wrap].filter_see_results :deep(.see-results),
    [data-wrap]:not(.filter_active):not(.filter_see_results) .almost.final,
    [data-wrap].filter_active .ready,
    [data-wrap].filter_active :deep(.see-results),
    [data-wrap]:not(.filter_active):not(.filter_see_results) .button.skip {
      animation: fade-in .3s forwards;
    }
    [data-wrap].filter_see_results .almost,
    [data-wrap].filter_see_results .button.skip,
    [data-wrap].filter_active .almost,
    [data-wrap]:not(.filter_active):not(.filter_see_results) .ready,
    [data-wrap]:not(.filter_active):not(.filter_see_results) :deep(.see-results),
    [data-wrap].filter_active .button.skip {
      display: none;
    }
  }

  /*
  //  Desktop
  */
  @media only screen and (min-width: 1024px) {
    .brand .step-actions {
      display: none;
    }
  }
</style>
