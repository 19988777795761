<template>
  <div :class="[`step-${step}__wrap step__wrap`, filter.attribute_code, store.selectedProductType.length > 0 ? 'filter_active' : '']" data-wrap>
    <div :class="[`step-${step}__mobile-label step-mobile-label`]">
      <h2>{{ getLabel(filter) }}</h2>
    </div>
    <div :class="[`step-${step}__select custom-select`]" :data-step="step">
      <div class="select-selected" @click="toggleFilters">{{ getLabel(filter) }}
      <span class="count" v-if="store.selectedProductType.length > 0">
      {{ store.selectedProductType.length > 0 ? store.selectedProductType.length : '' }}
      </span></div>
      <div :class="['select-items select-hide']">
        <ol class="items">
          <li v-for="option in getOptionsToShow(results.data, results.options)" :key="option.value">
            <label class="label" :for="results.name + ':' + option.value" :data-label="option.value" :class="{'active': store.selectedProductType.includes(option.value)}">
              <input type="checkbox"
                     :id="results.name + ':' + option.value"
                     v-model="store.selectedProductType"
                     :value="option.value"
                     :data-filter="results.name + ':' + option.value"
                     @change="activeFilters"
              />
              <span class="checkmark"></span>
              <div class="label-text">
                <div class="mobile-icon">
                  <img v-if="getIcons(icons, option.display_name)" :src="getIcons(icons, option.display_name)" alt="">
                </div>
                <span class="text">{{ option.display_name }}</span>
              </div>
            </label>
          </li>
        </ol>
      </div>
    </div>

    <div :class="[`step-${step}__actions step-actions`]">
      <p :class="['almost', step == '4' ? 'final' : '']" v-if="step == '4' || step == '3'">Almost There...</p>
      <p class="ready" v-if="step == '4'">Let’s Do This!</p>
      <button class="button skip" v-if="step == '4'" @click="showNextButton">Skip</button>
      <button class="button next" v-if="step != '4'" @click="triggerNextStep">Next</button>
      <button class="button skip" v-if="step != '4'" @click="triggerNextStep">Skip</button>
      <ButtonMobile v-if="step == '4'" />

    </div>
  </div>
</template>

<script>
  import ButtonMobile from  "@/components/ButtonMobile.vue";
  import { store } from '@/store'

  export default {
    name: "ProductType",
    components: {
      ButtonMobile
    },
    props: {
      filter: {
        type: Object,
        required: true
      },
      results: {
        type: Object,
        required: true
      },
      icons: {
        type: Object,
        required: true
      },
      step: {
          default: '',
          type: String,
          required: true
      }
    },
    emits: ['facetChange'],
    data() {
      return {
        store,
        isDesktop: window.innerWidth > 1023
      };
    },
    methods: {
      getIcons(icons, type) {
        let type_formatted = type.toLowerCase();
        const filtered = Object.values(icons).filter(item => item.product_type_icon.toLowerCase() == type_formatted);
        return filtered[0] ? filtered[0].image : '';
      },
      triggerNextStep() {
        let nextStep = parseFloat(this.step) + 1;
        document.querySelector(`.step.step-${this.step}.active`)?.classList.remove('active');
        document.querySelector(`.timeline-step.step-${this.step}.active`)?.classList.remove('active');
        document.querySelector(`.timeline-step.step-${nextStep}`).classList.add('active');
        document.querySelector(`.step.step-${nextStep}`).classList.add('active');
        let nextStepOffset = document.querySelector(`.step-${nextStep}__wrap`).offsetTop - 40;
        if (!this.isDesktop) {
          document.querySelector('.step-wrapper').scrollTo({
            top: nextStepOffset,
            behavior: 'smooth'
          });
          document.querySelector('.step-wrapper').style.maxHeight = document.querySelector(`.step-${nextStep}__wrap`).clientHeight + 40 + 'px'
        }
      },
      toggleFilters(e) {
        let thisStep = e.target.closest('.custom-select').getAttribute('data-step'),
            customSelect = document.querySelector('.custom-select.active:not([data-step="' + thisStep + '"])');

        if (customSelect) {
          document.querySelector('.custom-select.active').classList.remove('active')
        }

        e.target.closest('.custom-select').classList.contains('active') ?
            e.target.closest('.custom-select').classList.remove('active')
            :
            e.target.closest('.custom-select').classList.add('active')
      },
      showNextButton(e) {
        e.target.closest('[data-wrap]').classList.add('filter_see_results')
      },
      activeFilters() {
        let product_types = store.selectedProductType;

        // Add updated vmodel to local storage
        store.setProductType(product_types);

        this.$emit('facetChange');
      },
      getOptionsToShow(data, options) {
        let optionsToShow = [];

        if (data === undefined || options === undefined) {
          return optionsToShow;
        }

        let enabledMobile = Object.values(this.icons).filter(item => item.display_on_mobile == 1);

        if (!this.isDesktop && enabledMobile.length > 0) {
          for (const entry of options) {
            if (enabledMobile.some(element => element.product_type_icon == entry.display_name)) {
              optionsToShow.push(entry);
            }
          }

          return optionsToShow;
        }

        // M2PL-3490-removed showMore options
        if (data?.max_number_filter_options){
          return options.slice(0, data.max_number_filter_options)
        }
        return options;
      },

      getLabel(filter) {
        let label = filter.label;
        if (!this.isDesktop && filter.mobile_label) {
            label = filter.mobile_label;
        } else if (filter.desktop_label) {
            label = filter.desktop_label;
        }
        return label;
      }
    }
  }
</script>

<style scoped>
  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  /*
  //  Mobile
  */
  @media only screen and (max-width: 1023px) {
    .item_type.step__wrap ol.items li input,
    .item_type.step__wrap ol.items li:nth-child(n+5) {
      display: none;
    }
    .item_type.step__wrap input[name="item-type"] {
        display: none;
    }
    .item_type.step__wrap ol.items {
      width: 100%;
      display: inline-block;
      margin: 0 auto;
      max-width: 460px;
    }
    .item_type.step__wrap ol.items li {
      float: left;
      margin: 0 10px 20px;
      width: calc(50% - 20px);
    }
    .item_type.step__wrap ol.items li:nth-child(odd) {
      clear: left;
    }
    .item_type.step__wrap ol.items li label {
      width: 100%;
    }
    .item_type.step__wrap .label-text {
      font-size: var(--font-size-medium);
    }
    .item_type.step__wrap .label-text .text {
      max-width: 160px;
      margin: 0 auto;
      display: block;
    }
    .item_type.step__wrap .label-text .mobile-icon {
      border: 10px solid var(--color-white);
      border-radius: 100%;
      background: #E0E4E4;
      padding-bottom: calc(100% - 20px);
      width: 100%;
      max-width: 200px;
      height: 0;
      cursor: pointer;
      margin-bottom: 12px;
      transition: all 0.3s ease;
      position: relative;
    }
    .item_type.step__wrap .label-text .mobile-icon img {
      width: 100%;
    }
    .item_type.step__wrap .label-text .mobile-icon:after {
      height: calc(100% + 20px);
      width: calc(100% + 20px);
      content: '';
      display: block;
      border-radius: 100%;
      position: absolute;
      top: -11px;
      left: -11px;
    }
    .item_type.step__wrap .label.active .label-text .mobile-icon {
      color: var(--color-white);
    }
    .item_type.step__wrap .label.active .label-text .mobile-icon:after {
      border: 2px solid var(--color-black);
    }
    [data-wrap].filter_active .button.next,
    [data-wrap]:not(.filter_active) .button.skip {
      animation: fade-in .3s forwards;
    }
    [data-wrap]:not(.filter_active) .button.next,
    [data-wrap].filter_active .button.skip {
      display: none;
    }
    [data-wrap].filter_see_results .ready,
    [data-wrap].filter_see_results :deep(.see-results),
    [data-wrap]:not(.filter_active):not(.filter_see_results) .almost.final,
    [data-wrap].filter_active .ready,
    [data-wrap].filter_active :deep(.see-results),
    [data-wrap]:not(.filter_active):not(.filter_see_results) .button.skip {
      animation: fade-in .3s forwards;
    }
    [data-wrap].filter_see_results .almost.final,
    [data-wrap].filter_see_results .button.skip,
    [data-wrap].filter_active .almost.final,
    [data-wrap]:not(.filter_active):not(.filter_see_results) .ready,
    [data-wrap]:not(.filter_active):not(.filter_see_results) :deep(.see-results),
    [data-wrap].filter_active .button.skip {
      display: none;
    }
  }

  /*
  //  Desktop
  */
  @media only screen and (min-width: 1024px) {
    .item_type .step-actions {
      display: none;
    }
    .mobile-icon {
      display: none;
    }
  }
</style>
