<template>
  <div :class="[`step-${step}__wrap step__wrap`, filter.attribute_code, store.selectedColor.length > 0 ? 'filter_active' : '']" data-wrap>
    <div :class="[`step-${step}__mobile-label step-mobile-label`]">
      <h2>{{ getLabel(filter) }}</h2>
    </div>
    <div :class="[`step-${step}__select custom-select`]" :data-step="step">
      <div class="select-selected" @click="toggleFilters">{{ getLabel(filter) }}
        <span class="count" v-if="store.selectedColor.length > 0">
        {{ store.selectedColor.length > 0 ? store.selectedColor.length : '' }}
      </span></div>
      <div :class="['select-items select-hide']">
        <ol class="items color">
          <li v-for="option in getOptionsToShow(results.data, results.options)" :key="option.value">
            <label class="label" :for="results.name + ':' + option.value" :data-label="option.value">
                <input type="checkbox"
                  :data-color="option.value"
                  :id="results.name + ':' + option.value"
                  v-model="store.selectedColor"
                  :value="option.value"
                  :data-filter="results.name + ':' + option.value"
                  @change="activeFilters"
                />
                <span :style="getSwatchBackground(option)" class="checkmark" :data-color="option.display_name"></span>
                <span class="text">{{ option.display_name }}</span>
            </label>
          </li>
        </ol>
      </div>
    </div>
    <div :class="[`step-${step}__actions step-actions`]">
      <p :class="['almost', step == '4' ? 'final' : '']" v-if="step == '4' || step == '3'">Almost There...</p>
      <p class="ready" v-if="step == '4'">Let’s Do This!</p>

      <button class="button skip" v-if="step == '4'" @click="showNextButton">Skip</button>
      <button class="button next" v-if="step != '4'" @click="triggerNextStep">Next</button>

      <button class="button skip" v-if="step != '4'" @click="triggerNextStep">Skip</button>

      <ButtonMobile v-if="step == '4'" />
    </div>
  </div>
</template>

<script>
import ButtonMobile from  "@/components/ButtonMobile.vue";
import { store } from '@/store'

export default {
  name: "Colors",
  components: {
    ButtonMobile
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    results: {
      type: Object,
      required: true
    },
    step: {
        default: '',
        type: String,
        required: true
    }
  },
  emits: ['facetChange'],
  data() {
      return {
        store,
        isDesktop: window.innerWidth > 1023
      };
  },
  methods: {
    triggerNextStep() {
      let nextStep = parseFloat(this.step) + 1;
      document.querySelector(`.step.step-${this.step}.active`)?.classList.remove('active')
      document.querySelector(`.timeline-step.step-${this.step}.active`)?.classList.remove('active')
      document.querySelector(`.timeline-step.step-${nextStep}`).classList.add('active')
      document.querySelector(`.step.step-${nextStep}`).classList.add('active')
        let nextStepOffset = document.querySelector(`.step-${nextStep}__wrap`).offsetTop - 40;
        if(!this.isDesktop) {
          document.querySelector('.step-wrapper').scrollTo({
            top: nextStepOffset,
            behavior: 'smooth'
          });
          document.querySelector('.step-wrapper').style.maxHeight = document.querySelector(`.step-${nextStep}__wrap`).clientHeight + 40 + 'px'
        }
    },
    toggleFilters(e) {
      let thisStep = e.target.closest('.custom-select').getAttribute('data-step'),
          customSelect = document.querySelector('.custom-select.active:not([data-step="' + thisStep + '"])');

      if (customSelect) {
        document.querySelector('.custom-select.active').classList.remove('active')
      }

      e.target.closest('.custom-select').classList.contains('active') ?
          e.target.closest('.custom-select').classList.remove('active')
          :
          e.target.closest('.custom-select').classList.add('active')
    },
    activeFilters() {
      let colors = store.selectedColor

      // Add updated vmodel to local storage
      store.setColor(colors)

      this.$emit('facetChange');
    },
    showNextButton(e) {
      e.target.closest('[data-wrap]').classList.add('filter_see_results')
    },
    getOptionsToShow(data, options) {
        // M2PL-3490-removed showMore options
        if (data?.max_number_filter_options){
          return options.slice(0, data.max_number_filter_options)
        }
        return options;
    },

    getLabel(filter) {
      let label = filter.label;
      if (!this.isDesktop && filter.mobile_label) {
          label = filter.mobile_label;
      } else if (filter.desktop_label) {
          label = filter.desktop_label;
      }
      return label;
    },

    getSwatchBackground(swatch) {
      let style;
      if (swatch.data.swatch_type === '1') {
        style = 'background-color: ' + swatch.data.swatch_value + ';';
      } else if (swatch.data.swatch_type === '2') {
        style = 'background: url(' + window.location.origin + '/media/attribute/swatch/swatch_image/30x20' + swatch.data.swatch_value + ') no-repeat center; background-size: 110%;';
      }
      return style;
    }
  }
}
</script>


<style scoped>
  .color .step-actions {
    display: none;
  }
  .color .custom-select ol.items {
    display: flex;
    flex-wrap: wrap;
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
  }
  .color .custom-select .items li {
    width: 50%;
    text-align: left;
    margin-bottom: 13px;
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
  }
  .color .custom-select .items li .text {
    margin-left: 50px;
    display: block;
  }
  .color .custom-select .items li label {
    cursor: pointer;
  }
  .color .custom-select .items li input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .step_4__actions {
    padding-bottom: 30px;
  }
  .color .custom-select .items li .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    background-color: var(--color-lighter-gray);
    border: 1px solid transparent;
    border-radius: 100%;
    cursor: pointer;
  }
  /* not replacing these color values since it's only used here */
  .color .custom-select ol.items li .checkmark[data-color="Black"] {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
  .color .custom-select ol.items li .checkmark[data-color="Navy"] {
    background-color: #0B2A54;
    border-color: #0B2A54;
  }
  .color .custom-select .select-items ol.items li .checkmark[data-color="White"] {
    background-color: #F5F3F0;
    border-color: #F5F3F0;
  }
  .color .custom-select .select-items ol.items li .checkmark[data-color="Pewter"] {
    background-color: #525252;
    border-color: #525252;
  }
  .color .custom-select .select-items .items li .checkmark[data-color="Royal"] {
    background-color: #164389;
    border-color: #164389;
  }
  .color .custom-select .items li .checkmark[data-color="Wine"] {
    background-color: #591B35;
    border-color: #591B35;
  }
  .color .custom-select .items li .checkmark[data-color="Ceil"] {
    background: #8AA6CF;
    border-color: #8AA6CF;
  }
  .color .custom-select .items li .checkmark[data-color="Carribean"] {
    background: #1F5169;
    border-color: #1F5169;
  }
  .color .custom-select .items li .checkmark[data-color="Hunter"] {
    background: #204F3F;
    border-color: #204F3F;
  }
  .color .custom-select .items li .checkmark[data-color="Olive"] {
    background: #677E30;
    border-color: #677E30;
  }
  .color .custom-select .items li .checkmark[data-color*="Pink"] {
    background: #EE5897;
    border-color: #EE5897;
  }
  .color .custom-select .items li .checkmark[data-color*="Orange"] {
    background: #E98734;
    border-color: #E98734;
  }
  .color .custom-select .items li .checkmark[data-color*="Grey"] {
    background: #A3A3A3;
    border-color: #A3A3A3;
  }
  .color .custom-select .items li .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .color .custom-select .items li input:checked ~ .checkmark:after {
    display: block;
  }
  .color .custom-select .select-items ol.items li .checkmark:after {
    left: 12px;
    top: 7px;
    width: 9px;
    height: 15px;
    border: solid var(--color-white);
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .color .custom-select .select-items ol.items li .checkmark[data-color="White"]:after {
    border-color: var(--color-black);
  }

  /*
  //  Mobile
  */
  @media only screen and (max-width: 1023px) {
    .color div.step-actions {
      display: block;
    }
    .color.step__wrap ol.items li:nth-child(n+20) {
      display: none;
    }
    [data-wrap].filter_see_results .ready,
    [data-wrap].filter_see_results :deep(.see-results),
    [data-wrap]:not(.filter_active):not(.filter_see_results) .almost.final,
    [data-wrap].filter_active .ready,
    [data-wrap].filter_active :deep(.see-results),
    [data-wrap]:not(.filter_active):not(.filter_see_results) .button.skip {
      animation: fade-in .3s forwards;
    }
    [data-wrap].filter_see_results .almost.final,
    [data-wrap].filter_see_results .button.skip,
    [data-wrap].filter_active .almost.final,
    [data-wrap]:not(.filter_active):not(.filter_see_results) .ready,
    [data-wrap]:not(.filter_active):not(.filter_see_results) :deep(.see-results),
    [data-wrap].filter_active .button.skip {
      display: none;
    }
    .color .custom-select ol.items {
      width: 100%;
    }
    [data-wrap].filter_active .button.next,
    [data-wrap]:not(.filter_active) .button.skip {
      animation: fade-in .3s forwards;
    }
    [data-wrap]:not(.filter_active) .button.next,
    [data-wrap].filter_active .button.skip {
      display: none;
    }
  }

  /*
  //  Desktop
  */
  @media only screen and (min-width: 1024px) {
    .color .custom-select ol.items li .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 32px;
      width: 32px;
    }
    .color .custom-select .select-items ol.items li .checkmark:after {
      left: 11px;
      top: 6px;
    }
  }
</style>
