import { store, selectedFilters } from '@/store'

export function redirect() {
  let target;
  let skipBrand = false;

  localStorage.setItem('gender_selection', JSON.stringify(store.selectedGender));
  localStorage.setItem('product_type_selection', JSON.stringify(store.selectedProductType));
  localStorage.setItem('brand_selection', JSON.stringify(store.selectedBrand));
  localStorage.setItem('color_selection', JSON.stringify(store.selectedColor));
  localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters._value));

  if (store.selectedBrand.length === 1) {
    target = new URL(window.location.origin + '/catalogsearch/result/');
    target.searchParams.append('q', store.selectedBrand[0]);
    skipBrand = true;
  } else {
    target = new URL(window.location.origin + store.redirects[store.selectedGender.toLowerCase()]);
  }

  let filters = Object.entries(selectedFilters.value);
  filters.forEach(filter => {
    if (filter[0] === 'brand' && skipBrand) {
      return;
    }

    filter[1].forEach(filterValue => {
      target.searchParams.append('f.' + filter[0], filterValue);
    });
  });

  target.searchParams.append('qf', '1');

  window.location.href = target.toString();
}
