  // store.js
import { computed, reactive } from 'vue'

export const store = reactive({
  selectedGender: JSON.parse(localStorage.getItem('gender_selection')) ?? (window.innerWidth > 1023 ? 'Womens' : ''),
  selectedProductType: JSON.parse(localStorage.getItem('product_type_selection')) ?? [],
  selectedBrand: JSON.parse(localStorage.getItem('brand_selection')) ?? [],
  selectedColor: JSON.parse(localStorage.getItem('color_selection')) ?? [],
  redirects: {},
  setGender(gender) {
    this.selectedGender = gender;
  },
  setProductType(productType) {
    this.selectedProductType = productType;
  },
  setBrand(brand) {
    this.selectedBrand = brand;
  },
  setColor(color) {
    this.selectedColor = color;
  },
  setRedirects(redirects) {
    this.redirects = redirects;
  }
})

export const selectedFilters = computed(() => {
  let filters = {};

  if (store.selectedGender.length) {
    filters.gender = [store.selectedGender];
  }

  if (store.selectedProductType.length) {
    filters.item_type = [];
    store.selectedProductType.forEach(p => {
      filters.item_type.push(p);
    })
  }

  if (store.selectedBrand.length) {
    filters.brand = [];
    store.selectedBrand.forEach(b => {
      filters.brand.push(b);
    })
  }

  if (store.selectedColor.length) {
    filters.color = [];
    store.selectedColor.forEach(c => {
      filters.color.push(c);
    })
  }

  return filters;
});
