<template>
  <QuickFilters :filters="filters" :redirects="redirects" />
</template>

<script>
import QuickFilters from './components/QuickFilters.vue';
import './global.css';

export default {
  name: 'App',
  props: [
    'filters',
    'redirects'
  ],
  components: {
    QuickFilters
  }
}
</script>


<style>
#quick_filter {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Circular Std', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 50px auto 0;
}

@media only screen and (max-width: 1023px) {
  div#quick_filter {
    padding: 0 30px;
    margin: 80px auto 0;
  }
}
</style>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}
</style>
