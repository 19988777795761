<template>
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.4005 7.50584L0.672129 1.34501C0.447513 1.10343 0.447513 0.711767 0.672128 0.470195C0.896744 0.228622 1.26092 0.228622 1.48553 0.470194L7.6206 7.06843C7.84522 7.31 7.84522 7.70167 7.6206 7.94324L1.48553 14.5415C1.26092 14.783 0.896745 14.783 0.67213 14.5415C0.447514 14.2999 0.447514 13.9082 0.67213 13.6667L6.4005 7.50584Z" fill="white"/>
    <path d="M13.838 7.50584L8.10963 1.34501C7.88501 1.10343 7.88501 0.711767 8.10963 0.470195C8.33424 0.228622 8.69842 0.228622 8.92303 0.470194L15.0581 7.06843C15.2827 7.31 15.2827 7.70167 15.0581 7.94324L8.92303 14.5415C8.69842 14.783 8.33424 14.783 8.10963 14.5415C7.88501 14.2999 7.88501 13.9082 8.10963 13.6667L13.838 7.50584Z" fill="white"/>
    </svg>
</template>

<script>
export default {
  name: "Go"
}
</script>
