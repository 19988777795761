<template>
    <div :class="[`step-${step}__wrap step__wrap`, filter.attribute_code]">
        <div :class="[`step-${step}__mobile-label step-mobile-label`]">
          <h2>{{ getLabel(filter) }}</h2>
        </div>
        <div :class="[`step-${step}__options step__options`]">
            <div :class="[`step-${step}__option-1 step-${step}__option step__option`]">
                <label class="label" for="womens">
                    <input id="womens" type="radio" :name="filter.attribute_code" value="Womens" @click="stepToggle" @change="setGender" v-model="selectedGender" />
                    <span class="checkmark"></span>
                    <span class="label-text" :class="{active: active === 'Womens'}">Women's <span class="mobile-text">Scrubs</span></span>
                </label>
            </div>
            <div :class="[`step-${step}__option-2 step-${step}__option step__option`]">
                <label class="label" for="mens">
                    <input id="mens" type="radio" :name="filter.attribute_code" value="Mens" @click="stepToggle" @change="setGender" v-model="selectedGender" />
                    <span class="checkmark"></span>
                    <span class="label-text" :class="{active: active === 'Mens'}">Men's <span class="mobile-text">Scrubs</span></span>
                </label>
            </div>
        </div>
    </div>
</template>


<script>
import { store } from '@/store'

export default {
    name: "Gender",
    emits: ['facetChange'],
    props: {
        filter: {
            type: Object,
            required: true
        },
        step: {
            default: '',
            type: String,
            required: true
        }
    },
    data() {
        return {
            store,
            selectedGender: window.innerWidth > 1023 ? store.selectedGender : null,
            isDesktop: window.innerWidth > 1023,
            active: null,
        };
    },
    computed: {
        storeSelectedGender() {
            return store.selectedGender;
        }
    },
    watch: {
        storeSelectedGender(newSelectedGender) {
            if (newSelectedGender !== this.selectedGender) {
                this.selectedGender = newSelectedGender;
                this.$emit('facetChange');
            }
        },
        selectedGender(newSelectedGender) {
            this.active = newSelectedGender;
        }
    },
    methods: {
        getLabel(filter) {
            let label = filter.label;
            if (!this.isDesktop && filter.mobile_label) {
                label = filter.mobile_label;
            } else if (filter.desktop_label) {
                label = filter.desktop_label;
            }
            return label;
        },
        stepToggle() {
            setTimeout(this.stepClassToggle, 450);
        },
        stepClassToggle() {
            let activeStep = document.querySelector(`.step.step-${this.step}.active`),
                nextStep = parseFloat(this.step) + 1,
                timelineStep = document.querySelector(`.timeline-step.step-${this.step}.active`);

            console.log(`.step-${nextStep}__wrap`)

            if (activeStep) {
                activeStep.classList.remove('active')
            }

            if (timelineStep) {
              timelineStep.classList.remove('active')
            }

            document.querySelector(`.timeline-step.step-${nextStep}`).classList.add('active')
            document.querySelector(`.step.step-${nextStep}`).classList.add('active')
        },
        setGender() {
            if (this.selectedGender !== store.selectedGender) {
                store.setGender(this.selectedGender);
                store.setProductType([]);
                store.setColor([]);
                store.setBrand([]);

                this.$emit('facetChange');
            }

            if(window.innerWidth > 1024) {
                return false
            }

            document.querySelector('.step-wrapper').classList.add('ready')
        }
    }
}
</script>

<style scoped>
/* Gender */
.gender .step__options {
    color: var(--brand-primary);
    display: flex;
}

/*
//  Mobile
*/
@media only screen and (max-width: 1023px) {
    .quick-filters .gender .step-1__mobile-label  {
        margin-top: 0;
    }
    .gender .step__options {
        justify-content: center;
        padding: 0 5px;
        gap: 5%;
    }
    .gender .step__options .step__option {
        flex: 1;
        max-width: 200px;
    }
    .gender .step__options .step__option .label {
        width: 100%;
        display: block;
        height: 0;
        padding-bottom: 60%;
        position: relative;
        cursor: pointer;
        border: 1px solid var(--brand-primary);
        border-radius: 9999px;
        overflow: hidden;
    }
    .gender .step__options .step__option .label-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        font-size: var(--font-size-medium);
    }
    input[name="gender"] {
        display: none;
    }
    .label-text.active {
        background: var(--brand-primary);
        color: var(--color-white);
    }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .gender .step__options {
        gap: 30px;
    }
}

/*
// Desktop
*/
@media only screen and (min-width: 1024px) {
    .step-mobile-label {
        display: none;
    }
    .mobile-text {
        display: none;
    }
    .gender.step__wrap label:first-child {
        margin-right: 20px;
    }
    .gender.step__wrap label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: var(--font-weight-bold);
        color: var(--color-black);
        gap: 5px;
    }
    .gender.step__wrap label span.label-text {
        font-size: var(--font-size-medium);
        display: block;
    }
    .gender.step__wrap label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .gender.step__wrap label .checkmark {
        position: relative;
        height: 24px;
        width: 24px;
        background-color: var(--color-white);
        border: 1px solid var(--color-gray);
        border-radius: 100%;
        transition: all 0.3s ease;
    }
    .gender.step__wrap label:hover .checkmark,
    .gender.step__wrap label input:checked ~ .checkmark {
        background-color: var(--color-white);
    }
    .gender.step__wrap label .checkmark:after {
        content: "";
        position: absolute;
        opacity: 0;
        transition: all 0.2 s ease;
        left: 5px;
        top: 5px;
        width: 12px;
        height: 12px;
        background: var(--brand-primary);
        border-radius: 100%;
    }
    .gender.step__wrap label:hover .checkmark:after,
    .gender.step__wrap label input:checked ~ .checkmark:after {
        opacity: 1;
    }
}
</style>
