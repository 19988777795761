<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11">
    <polyline stroke="#FFF" points="1.4,5.8 5.1,9.5 11.6,2.1 "/>
    </svg>
</template>

<script>
export default {
  name: "Check"
}
</script>
